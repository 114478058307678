//import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import {
  Header,
  HeaderName
} from "carbon-components-react/lib/components/UIShell";

const Headerr = ({ siteTitle }) => (
  <>
  {/** 
  <div
    style={{
      background: ``,
      marginBottom: `1.45rem`,
    }}
  > 
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </div>
  */}
  <Header aria-label="IBM Platform Name" style={{
      background: ``,
      marginBottom: `1.45rem`,
    }}>
      <HeaderName href="#" prefix="SmartBits">
        [{siteTitle}]
      </HeaderName>
  </Header>
  </>
)

Headerr.propTypes = {
  siteTitle: PropTypes.string,
}

Headerr.defaultProps = {
  siteTitle: ``,
}

export default Headerr


/*
<Header aria-label="IBM Platform Name" >
      <HeaderName href="#" prefix="IBM">
        [Platform]
      </HeaderName>
    </Header>*/